<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'Leaflet',
};
</script>
<style lang="scss" scoped>
.page {
  max-width: 750px;
  min-height: 100%;
  margin: auto;
  min-height: 720px;
  // background: linear-gradient(0deg, #00218f 0%, #0027aa 100%);
  // overflow: hidden;
}
</style>
